<template>
    <div class="project-hero-banner" :class="$mq">
        <div class="hero-bg-image">
            <img v-parallax="0.1" :src="bgImage" alt="Olina Lab logo" />
        </div>
        <div class="hero-title zerotoone px-5">
            <h1 class="text-uppercase" v-html="title"></h1>
            <p>
                {{ subtitle }}
            </p>
            <div class="hero-discover text-uppercase">
                Découvrir 
                <span>
                    <i class="fas fa-caret-down ml-3 sd-first"></i>
                </span>
                <span>
                    <i class="fas fa-caret-down ml-1 sd-second"></i>
                </span>
                <span>
                    <i class="fas fa-caret-down ml-1 sd-third"></i>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProjectHeroBanner",

    props: {
        bgImage: String,
        title: String,
        subtitle: String
    }
}
</script>

<style lang="scss" scoped>
    .project-hero-banner {
        padding-top: 72px;

        background-color: $primary;
        height: 100vh;

        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;

        .hero-bg-image {
            content: "";
            opacity: 0.1;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .hero-title {
            margin-top: -72px;
            max-width: 50%;

            .hero-cta {
                position: absolute;
                bottom: 70px;
                left: 0;
                right: 0;
            }

            h1 {
                font-size: 100px;
                font-weight: bold;
            }
        }

        &.tablet {
            padding-top: 36px;

            .hero-title {
                margin-top: -36px;

                p {
                    font-size: 14px;
                }

                h1 {
                    font-size: 60px;
                }
            }
        }

        &.mobile {
            .hero-title {
                max-width: 80%;

                p {
                    font-size: 14px;
                }

                h1 {
                    font-size: 32px;
                }
            }
        }

        @media screen and (max-width: 320px) {
            padding-top: 0;
        }
    }
</style>