<template>
	<div class="projects">
		<ProjectHeroBanner
			:bgImage="require('../assets/img/bg-mockup.png')"
			title="Nos projets"
			subtitle="Renseignez-vous sur les derniers projets marquants menés par l'équipe d'Olina Lab, que ce soit des sites web, des applications web ou encore des landing pages."
		/>
		<ProjectSlider />
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

import ProjectHeroBanner from '@/components/Banner/ProjectHeroBanner.vue'
import ProjectSlider from '@/components/Slider/ProjectSlider.vue'

export default {
	name: "Projects",
	components: {
		ProjectHeroBanner,
		ProjectSlider
	}
}
</script>

<style lang="scss" scoped>
	.projects {
		overflow-x: hidden;
	}
</style>