<template>
    <div class="project-slider" :class="$mq">
        <div class="project-filter my-3" v-if="$mq === 'mobile'">
            <div class="filter pb-2" v-for="cat in categories" :key="cat.cat" @click="filter(cat)" :class="{ 'active': activeCategorie.cat === cat.cat }">
                {{ cat.label }}
            </div>
        </div>
        <div class="project-count">
            <div class="count">
                <div class="step-container">
                    <div class="step" v-if="activeCategorie.cat === 'all'">
                        {{ ('0' + (this.hiddenProjects.length + 1)).slice(-2) }}
                    </div>
                    <div class="step" v-else>
                        {{ ('0' + (this.hiddenProjects.length - (this.projects.length - total) + 1)).slice(-2) }}
                    </div>
                    <div class="total">
                        {{ ('0' + total).slice(-2) }}
                    </div>
                </div>
                <div class="progress-bar">
                    <div class="fill-progress" :style="{ 'width': ((this.hiddenProjects.length + 1)/total)*100 + '%' }" v-if="activeCategorie.cat === 'all'"></div>
                    <div class="fill-progress" :style="{ 'width': ((this.hiddenProjects.length - (this.projects.length - total) + 1)/total)*100 + '%' }" v-else></div>
                </div>
            </div>
            <div class="actions mb-5">
                <div class="prev mr-3" @click="prev()">
                    <span class="icon mdi mdi-arrow-left-drop-circle-outline"></span>
                </div>
                <div class="next" @click="next()">
                    <span class="icon mdi mdi-arrow-right-drop-circle-outline"></span>
                </div>
            </div>
        </div>
        <div class="project-card-container">
            <div class="project-filter" v-if="$mq !== 'mobile'">
                <div class="filter pb-2" v-for="cat in categories" :key="cat.cat" @click="filter(cat)" :class="{ 'active': activeCategorie.cat === cat.cat }">
                    {{ cat.label }}
                </div>
            </div>
            <div class="project-card"
                v-for="project in projects"
                :key="project.id"
                :class="{ 'hide': hiddenProjects.indexOf(project) !== -1 }"
                @click="goTo(project.slug)"
            >
                <div class="picture" :style="{ backgroundImage: 'url(' + project.picture + ')' }"></div>
                <div class="content">
                    <h4>{{ project.title }}</h4>
                    <p>{{ project.desc }}</p>
                </div>
                <div class="view-project">
                    Voir le projet
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { ourProjects } from '@/data/projects.js'

export default {
	name: "ProjectSlider",
	data() {
		return {
			total: ourProjects.projects.length,
			projects: ourProjects.projects,
			activeProjects: [ ...ourProjects.projects ],
            hiddenProjects: [],

            categories: [
                { cat: 'all', label: 'Tous'},
                { cat: 'site', label: 'Site web' },
                { cat: 'sol', label: 'Solution web' }
            ],
            activeCategorie: null
		}
    },
    created() {
        this.activeCategorie = this.categories[0];
    },
	computed: {
        ...mapGetters({
            getRoute: 'ui/getRoute'
        })
    },
	methods: {
		prev() {
			if (this.activeProjects.length > 1) {
				this.hiddenProjects.push(this.activeProjects[0]);
				this.activeProjects.shift();
			}
		},
		next() {
            if (this.activeCategorie.cat === 'all') {
                if (this.hiddenProjects.length !== 0) {
                    this.activeProjects.unshift(this.hiddenProjects[this.hiddenProjects.length - 1]);
                    this.hiddenProjects.pop();
                }
            } else {
                if (this.hiddenProjects.length > (ourProjects.projects.length - this.total)) {
                    this.activeProjects.unshift(this.hiddenProjects[this.hiddenProjects.length - 1]);
                    this.hiddenProjects.pop();
                }
            }
        },
        filter(cat) {
            this.activeCategorie = cat;
            if (cat.cat === 'all') {
                this.activeProjects = [ ...ourProjects.projects ];
                this.hiddenProjects = [];
                this.total = ourProjects.projects.length;
            } else {
                let res = ourProjects.projects.filter(item => {
                    return item.type === cat.cat
                });

                this.activeProjects = [...res]
                this.hiddenProjects = ourProjects.projects.filter(item => {
                    return item.type !== cat.cat
                });
                this.total = res.length;
            }
        },
		goTo(slug) {
			this.$router.push(this.getRoute('SingleProject', slug));
		}
	}
}
</script>

<style lang="scss" scoped>
    .project-slider {
        display: grid;
        grid-template-columns: 33.33% 1fr;

        &.tablet {
            grid-template-columns: 50% 1fr;

            .project-card-container {
                .project-filter {
                    left: 50px;

                    .filter:not(:last-of-type) {
                        margin-right: 15px;
                    }
                }
                .project-card {
                    .view-project {
                        color: $white;
                        height: 40px;
                        line-height: 40px;
                    }
                }
            }
        }

        &.mobile {
            grid-template-columns: 100%;

            .project-filter {
                display: flex;
                justify-content: center;

                .filter {
                    width: 80px;
                    text-align: center;
                    cursor: pointer;
                    font-size: 14px;
                    border-color: transparent;
                    transition: border-color 450ms ease-in-out;

                    &:not(:last-of-type) {
                        margin-right: 15px;
                    }

                    &.active {
                        border-bottom: 2px solid $primary;
                    }
                }
            }

            .project-count {
                height: 200px;
                padding-bottom: 15px;

                .actions {
                    margin-bottom: 0 !important;
                }
            }

            .project-card-container {
                margin-left: 40px;
                margin-top: -15px;

                .project-card {
                    flex-shrink: 0;
                    margin-right: 40px;
                    margin-bottom: 10px;

                    &.hide {
                        width: 0;
                        margin-right: 0;
                        opacity: 0;
                        transform: translateX(-300px);
                        transform-origin: center;
                    }

                    .view-project {
                        color: $white;
                        height: 40px;
                        line-height: 40px;
                    }
                }
            }
        }

        .project-count {
            background: linear-gradient(-45deg, $secondary 15%, $primary);
            height: 600px;

            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding-bottom: 30px;

            .count {
                position: absolute;
                top: 25%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 48px;

                .step-container {
                    position: relative;
                    display: flex;

                    .total {
                        font-size: 16px;
                        padding-top: 15px;
                    }
                }

                .progress-bar {
                    height: 5px;
                    border-radius: 20px;
                    background-color: rgba(255, 255, 255, .7);

                    .fill-progress {
                        background-color: $white;
                        height: 5px;
                        transition: 450ms ease-in-out;
                        border-radius: 20px;
                    }
                }
            }
            
            .actions {
                max-width: 50%;
                display: flex;
                margin-top: auto;

                span {
                    cursor: pointer;
                    color: rgba(255, 255, 255, .7);
                    font-size: 48px;

                    &:hover {
                        color: $white;
                    }
                }
            }
        }

        .project-card-container {
            display: flex;
            margin-left: -30px;
            align-items: center;
            position: relative;

            .project-filter {
                position: absolute;
                left: 100px;
                top: 20px;
                display: flex;

                .filter {
                    width: 100px;
                    text-align: center;
                    cursor: pointer;
                    border-color: transparent;
                    transition: border-color 450ms ease-in-out;

                    &:not(:last-of-type) {
                        margin-right: 25px;
                    }

                    &.active {
                        border-bottom: 2px solid $primary;
                    }
                }
            }

            .project-card {
                margin-right: 30px;
                width: 300px;
                height: 350px;
                position: relative;
                z-index: 10;
                background-color: $white;
                color: $black;
                transition: 450ms ease-in-out;
                cursor: pointer;

                &.hide {
                    width: 0;
                    margin-right: 0;
                    opacity: 0;
                    transform: scale(0);
                    transform-origin: center;
                }

                .picture {
                    height: 50%;
                    background-size: cover;
                }

                .content {
                    padding: 20px 20px;
                    p {
                        font-size: 13px;
                    }
                }

                .view-project {
                    width: 100%;
                    text-align: center;
                    position: absolute;
                    bottom: 0;
                    background-color: $primary;
                    color: transparent;
                    height: 5px;
                    line-height: 0px;
                    transition: 450ms ease-in-out;
                }

                &:hover {
                    .view-project {
                        height: 50px;
                        line-height: 50px;
                        color: $white;
                    }
                }
            }
        }
    }
</style>